<template>
  <div
    class="card card-custom bgi-no-repeat card-stretch gutter-b animate__animated animate__fadeIn"
    style="
      background-position: right top;
      background-size: 30% auto;
      background-image: url('/media/svg/shapes/abstract-2.svg');
    "
  >
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
          </span>
        </div>
        <div>
          <button @click.prevent="refresh" class="btn btn-sm">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="media/svg/icons/Media/Repeat.svg" />
            </span>
          </button>
        </div>
      </div>
      <span
        v-if="total"
        class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
      >
        <span>{{ total | currencyFormat }}</span>
      </span>
      <span
        v-else
        class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
      >
        <span>{{ $t('GENERAL.WEEKLY_INDEX') }}...</span>
      </span>
      <span class="font-weight-bold text-muted font-size-sm">{{
        $t('GENERAL.CURRENT_BALANCE')
      }}</span>
      <span v-b-tooltip.hover.right="$t('GENERAL.ALL_COINS_IN_DEVICES')"
        ><i class="la la-info-circle ml-1"></i
      ></span>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'

export default {
  name: 'CurrentBalance',
  data() {
    return {
      total: 0,
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  mounted() {
    this.getCurrentBalance().then(() => {
      this.isLoaded = true
    })
  },
  methods: {
    async getCurrentBalance() {
      try {
        if (
          this.currentUserRole === 'ROLE_ADMIN' ||
          this.currentUserRole === 'ROLE_WORKER'
        ) {
          return await ApiService.get(
            'devices',
            `?company=${this.currentUserCompany}&pagination=false&new=1&select=flags`
          ).then(({ data }) => {
            data['hydra:member'].forEach((device) => {
              if (!device.isSaldoReducer) {
                this.total += parseFloat(device.saldo)
              }
            })
          })
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    refresh() {
      this.isLoaded = false
      this.total = 0
      this.getCurrentBalance().then(() => {
        this.isLoaded = true
      })
    },
  },
}
</script>
