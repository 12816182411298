<template>
  <div v-if="isAllReady">
    <div
      v-if="
        currentUserRole !== 'ROLE_ROOT' &&
        currentUserRole !== 'ROLE_SUPER_ADMIN'
      "
    >
      <div
        v-if="
          currentUserRole !== 'ROLE_JUNIOR' &&
          currentUserRole !== 'ROLE_WATCHER'
        "
        class="row mb-4"
      >
        <div
          class="col-md-12 d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center justify-content-start">
            <DatePicker
              ref="picker"
              v-model="range"
              :mode="'dateTime'"
              :masks="masks"
              :max-date="maxDate"
              :min-date="minDate"
              locale="hr"
              is-range
              is24hr
              :columns="$screens({ default: 1, lg: 1 })"
              @dayclick="dayClicked"
            >
              <template v-slot="{ inputEvents }">
                <span
                  v-on="inputEvents.start"
                  class="symbol symbol-50 symbol-light-primary symbol-hover cursor-pointer mr-3 animate__animated animate__fadeIn"
                >
                  <span
                    v-b-tooltip.hover.top="$t('GENERAL.GENERATE_REPORT')"
                    class="symbol-label"
                  >
                    <span class="svg-icon svg-icon-xl svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Devices/Diagnostics.svg"
                      />
                    </span>
                  </span>
                </span>
              </template>
              <template v-slot:footer>
                <div class="row p-2">
                  <div class="col pr-5">
                    <div
                      @click="generateReport(range)"
                      class="btn btn-primary btn-block"
                      :class="{
                        'spinner spinner-white spinner-right':
                          isGenerateReportInProgress,
                      }"
                      v-b-tooltip.hover.top="'Generate report'"
                    >
                      Generate
                    </div>
                  </div>
                </div>
                <div class="row p-2">
                  <div class="col pl-5 pr-0">
                    <div
                      @click="yesterday"
                      class="symbol symbol-40 symbol-light-primary"
                    >
                      <div
                        v-b-tooltip.hover.top="'Yesterday'"
                        class="symbol-label cursor-pointer"
                      >
                        Y
                      </div>
                    </div>
                  </div>
                  <div class="col p-0">
                    <div
                      @click="last7Days"
                      class="symbol symbol-40 symbol-light-primary"
                    >
                      <div
                        v-b-tooltip.hover.top="'-7 days'"
                        class="symbol-label cursor-pointer"
                      >
                        -7D
                      </div>
                    </div>
                  </div>
                  <div class="col p-0">
                    <div
                      @click="last30Days"
                      class="symbol symbol-40 symbol-light-primary"
                    >
                      <div
                        v-b-tooltip.hover.top="'-30 days'"
                        class="symbol-label cursor-pointer"
                      >
                        -30D
                      </div>
                    </div>
                  </div>
                  <div class="col p-0">
                    <div
                      @click="prevMonth"
                      class="symbol symbol-40 symbol-light-primary"
                    >
                      <div
                        v-b-tooltip.hover.top="'Last month'"
                        class="symbol-label cursor-pointer"
                      >
                        LM
                      </div>
                    </div>
                  </div>
                  <div class="col pl-0">
                    <div
                      @click="thisMonth"
                      class="symbol symbol-40 symbol-light-primary"
                    >
                      <div
                        v-b-tooltip.hover.top="'This month'"
                        class="symbol-label cursor-pointer"
                      >
                        TM
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </DatePicker>

            <span
              v-if="currentActiveModuleComponents?.dashboard.devicesChart"
              class="symbol symbol-50 symbol-light-primary symbol-hover cursor-pointer mr-3 animate__animated animate__fadeIn"
            >
              <span
                v-b-tooltip.hover.top="$t('GENERAL.SHOW_DEVICES_CHART')"
                @click="calculateDevicesSaldo"
                class="symbol-label"
              >
                <span class="svg-icon svg-icon-xl svg-icon-primary">
                  <inline-svg src="media/svg/icons/Electric/Outlet.svg" />
                </span>
              </span>
            </span>

            <span
              v-if="
                currentActiveModuleComponents?.dashboard
                  .devicesLast30DaysRevenue
              "
              class="symbol symbol-50 symbol-light-primary symbol-hover cursor-pointer mr-3 animate__animated animate__fadeIn"
            >
              <span
                v-b-tooltip.hover.top="$t('GENERAL.TRAFFIC_LAST_365_DAYS')"
                @click="calculateLast12MonthTraffic"
                class="symbol-label"
              >
                <span class="svg-icon svg-icon-xl svg-icon-primary">
                  <inline-svg src="media/svg/icons/Design/Select.svg" />
                </span>
              </span>
            </span>

            <span
              v-if="currentActiveModuleComponents?.dashboard.predictTraffic"
              @click="predictTraffic"
              class="symbol symbol-50 symbol-light-primary symbol-hover cursor-pointer animate__animated animate__fadeIn"
            >
              <span
                v-b-tooltip.hover.top="$t('GENERAL.PREDICT_TRAFFIC')"
                class="symbol-label"
              >
                <span class="svg-icon svg-icon-xl svg-icon-primary">
                  <inline-svg src="media/svg/icons/Shopping/Calculator.svg" />
                </span>
              </span>
            </span>
            <span v-if="pT.isLoaded" class="ml-5">
              <strong>Total:</strong>
              {{ pT.total | currencyFormat }}</span
            >
          </div>
        </div>
      </div>

      <div
        v-role:any="'admin'"
        v-if="
          currentActiveModuleComponents?.dashboard.indexCharts &&
          currentUserRole !== 'ROLE_JUNIOR'
        "
        class="row"
      >
        <div class="col-md-12">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide class="pb-5">
              <DailyIndexWidget
                v-if="dailyIndex.todayMinusSevenDays.isLoaded"
                @showDailyIndexHistory="showDailyIndexHistory"
                @toggleDailyIndexHybrid="toggleDailyIndexHybrid"
                :today-total="dailyIndex.today.total"
                :today-transactions="dailyIndex.today.transactions"
                :today-minus-seven-days-total="
                  dailyIndex.todayMinusSevenDays.total
                "
                :today-minus-seven-days-transactions="
                  dailyIndex.todayMinusSevenDays.transactions
                "
                :today-last-year-total="dailyIndex.todayMinus365Days.total"
                :today-last-year-transactions="
                  dailyIndex.todayMinus365Days.transactions
                "
              />
              <div
                v-else
                class="col-12 d-flex align-items-center justify-content-center pb-5"
                style="height: 540px"
              >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
            </swiper-slide>
            <swiper-slide v-if="currentUserRole !== 'ROLE_WATCHER'">
              <WeeklyIndexWidget
                v-if="weeklyIndex.normal.pastWeek.isLoaded"
                @showWeeklyIndexHistory="showWeeklyIndexHistory"
                @toggleWeeklyIndexHybrid="toggleWeeklyIndexHybrid"
                :today-total="dailyIndex.today.total"
                :this-week-total="weeklyIndex.normal.currentWeek.total"
                :this-week-transactions="
                  weeklyIndex.normal.currentWeek.transactions
                "
                :today-minus-seven-days-total="
                  dailyIndex.todayMinusSevenDays.total
                "
                :last-week-total="weeklyIndex.normal.pastWeek.total"
                :last-week-transactions="
                  weeklyIndex.normal.pastWeek.transactions
                "
                :today-transactions="dailyIndex.today.transactions"
                :today-minus-seven-days-transactions="
                  dailyIndex.todayMinusSevenDays.transactions
                "
                :last-year-current-week-total="
                  weeklyIndexPreviousYea.normal.currentWeek.total
                "
              />
              <div
                v-else
                class="col-12 d-flex align-items-center justify-content-center pb-5"
                style="height: 540px"
              >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
            </swiper-slide>
            <swiper-slide v-if="currentUserRole !== 'ROLE_WATCHER'">
              <MonthlyIndexWidget
                v-if="monthlyIndex.normal.pastMonth.isLoaded"
                @showMonthlyIndexHistory="showMonthlyIndexHistory"
                @toggleMonthlyIndexHybrid="toggleMonthlyIndexHybrid"
                :this-month-total="monthlyIndex.normal.currentMonth.total"
                :this-month-transactions="
                  monthlyIndex.normal.currentMonth.transactions
                "
                :last-month-total="monthlyIndex.normal.pastMonth.total"
                :last-month-transactions="
                  monthlyIndex.normal.pastMonth.transactions
                "
                :today-total="dailyIndex.today.total"
                :today-minus-seven-days-total="
                  dailyIndex.todayMinusSevenDays.total
                "
                :is-checked="monthlyIndex.isHybrid"
                :this-month-last-year="
                  monthlyIndexPreviousYea.normal.currentMonth.total
                "
              />
              <div
                v-else
                class="col-12 d-flex align-items-center justify-content-center pb-5"
                style="height: 540px"
              >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
            </swiper-slide>
            <swiper-slide v-if="currentUserRole !== 'ROLE_WATCHER'">
              <YearlyIndexWidget
                v-if="yearlyIndex.normal.currentYear.isLoaded"
                @yearlyMonthlyIndexHistory="showYearlyIndexHistory"
                @toggleYearlyIndexHybrid="toggleYearlyIndexHybrid"
                :today-total="dailyIndex.today.total"
                :until-today="yearlyIndex.normal.currentYear.total"
                :until-today-transactions="
                  yearlyIndex.normal.currentYear.transactions
                "
                :until-today-last-year="yearlyIndex.normal.pastYear.total"
                :until-today-last-year-transactions="
                  yearlyIndex.normal.pastYear.transactions
                "
              />
              <div
                v-else
                class="col-12 d-flex align-items-center justify-content-center pb-5"
                style="height: 540px"
              >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>

      <div
        v-if="
          currentUserRole !== 'ROLE_JUNIOR' &&
          currentUserRole !== 'ROLE_WATCHER'
        "
        class="row"
      >
        <div class="col-md-4">
          <CurrentBalance />
        </div>
        <div class="col-md-4">
          <CurrentMonthRevenue :today-traffic="dailyIndex.today.total" />
        </div>
        <div class="col-md-4">
          <LastMonthRevenue />
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <router-link
            to="/devices/list"
            class="card card-custom bg-light-primary cursor-pointer animate__animated animate__fadeIn mb-8"
          >
            <div class="card-body d-flex align-center">
              <div class="mr-5">
                <span
                  class="svg-icon svg-icon-3x svg-icon-success animate__animated animate__fadeIn animate__delay-1s"
                >
                  <inline-svg src="media/svg/icons/Media/Airplay.svg" />
                </span>
              </div>
              <div>
                <div
                  class="text-dark font-weight-bolder font-size-h2 animate__animated animate__fadeIn animate__delay-1s"
                >
                  {{ devices.length }}
                </div>
                <span class="text-dark mb-1 font-size-lg">{{
                  $t('GENERAL.DEVICES_TOTAL')
                }}</span>
              </div>
            </div>
          </router-link>
        </div>
        <div
          v-if="currentActiveModuleComponents?.dashboard.problematicDevices"
          class="col-md-4"
        >
          <div
            @click="toggleProblematicDevicesModal"
            class="card card-custom gutter-b bg-light-warning cursor-pointer animate__animated animate__fadeIn"
          >
            <div class="card-body d-flex align-center">
              <div class="mr-5">
                <span
                  class="svg-icon svg-icon-3x svg-icon-warning animate__animated animate__fadeIn animate__delay-1s"
                >
                  <inline-svg src="media/svg/icons/Media/Airplay.svg" />
                </span>
              </div>
              <div>
                <div
                  class="text-dark font-weight-bolder font-size-h2 animate__animated animate__fadeIn animate__delay-1s"
                >
                  {{ problematicDevices.length }}
                </div>
                <span class="text-dark mb-1 font-size-lg">{{
                  $t('GENERAL.PROBLEMATIC_TOTAL')
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            @click="toggleCriticalDevicesModal"
            class="card card-custom gutter-b bg-light-danger cursor-pointer animate__animated animate__fadeIn"
          >
            <div class="card-body d-flex align-center">
              <div class="mr-5">
                <span
                  class="svg-icon svg-icon-3x svg-icon-danger animate__animated animate__fadeIn animate__delay-1s"
                >
                  <inline-svg src="media/svg/icons/Media/Airplay.svg" />
                </span>
              </div>
              <div>
                <div
                  class="text-dark font-weight-bolder font-size-h2 animate__animated animate__fadeIn animate__delay-1s"
                >
                  {{ criticalDevices.length }}
                </div>
                <span class="text-dark mb-1 font-size-lg">{{
                  $t('GENERAL.CRITICAL_TOTAL')
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          currentUserRole !== 'ROLE_JUNIOR' &&
          currentUserRole !== 'ROLE_WATCHER'
        "
        class="row"
      >
        <div class="col">
          <DaysInMontyTraffic
            v-if="isLas30DaysTrafficLoaded"
            :traffic="last30DaysTraffic"
            :today-traffic="dailyIndex.today.total"
          />
          <div
            v-else
            class="col-12 d-flex align-items-center justify-content-center pb-5"
            style="height: 300px"
          >
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <DevicesTraffic v-if="isDevicesTrafficLast30DaysLoaded" />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <Last12MonthTraffic
            v-if="isDevicesLast12MonthTraffic"
            :traffic="devicesLast12MonthTraffic"
          />
        </div>
      </div>

      <div
        v-if="isGenerateReportLoaded"
        id="generateReportWrapper"
        class="row animate__animated animate__fadeIn"
      >
        <div class="col-md-12">
          <div class="card card-custom gutter-b">
            <div class="card-header border-0 py-5">
              <h3 class="card-title font-weight-bolder text-dark">
                Generated report result:
                <span class="ml-5"
                  ><span
                    class="label label-lg label-inline label-light-primary font-weight-bold"
                    >{{ range.start | formatDate }}</span
                  ></span
                >
                <span
                  ><span class="flex-shrink-0 my-2 mx-3"
                    ><i class="la la-arrow-right"></i></span
                ></span>
                <span
                  ><span
                    class="label label-lg label-inline label-light-primary font-weight-bold"
                    >{{ range.end | formatDate }}</span
                  ></span
                >
              </h3>
              <div class="card-toolbar">
                <button
                  @click.prevent="exportAsPDF"
                  class="btn btn-light-primary font-weight-bold px-10 mr-3"
                >
                  Export .pdf
                </button>
                <button
                  @click.prevent="exportAsExcel"
                  class="btn btn-light-primary font-weight-bold px-10"
                >
                  Export .xlsx
                </button>
              </div>
            </div>
            <div class="card-body">
              <div v-if="showEuroReport">
                <DeviceReportTable
                  v-if="isGenerateReportLoaded"
                  :traffic="reportDevices"
                >
                </DeviceReportTable>
              </div>
              <div v-else>
                <DeviceReportTableKn
                  v-if="isGenerateReportLoaded"
                  :traffic="reportDevices"
                >
                </DeviceReportTableKn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-modal
            @hide="hideDailyIndexHistory"
            id="daily_index_modal"
            size="xl"
            hide-header
            hide-footer
          >
            <div v-if="isDailyIndexHistoryShow">
              <b-button
                class="btn btn-light-primary mb-3"
                block
                @click="$bvModal.hide('daily_index_modal')"
                >Close
              </b-button>
              <DailyHistory :scale="30" :today-traffic="dailyIndex.today.total">
              </DailyHistory>
            </div>
          </b-modal>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-modal
            @hide="hideWeeklyIndexHistory"
            id="weekly_index_modal"
            size="xl"
            hide-header
            hide-footer
          >
            <div v-if="isWeeklyIndexShow">
              <b-button
                class="btn btn-light-primary mb-3"
                block
                @click="$bvModal.hide('weekly_index_modal')"
                >Close
              </b-button>
              <WeeklyHistory
                :scale="228"
                :this-week-total="weeklyIndex.normal.currentWeek.total"
              ></WeeklyHistory>
            </div>
          </b-modal>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-modal
            @hide="hideMonthlyIndexHistory"
            id="monthly_index_modal"
            size="xl"
            hide-header
            hide-footer
          >
            <div v-if="isMonthlyIndexShow">
              <b-button
                class="btn btn-light-primary mb-3"
                block
                @click="$bvModal.hide('monthly_index_modal')"
                >Close
              </b-button>
              <MonthlyHistory
                :scale="228"
                :monthly-traffic="dailyIndex.today.total"
              ></MonthlyHistory>
            </div>
          </b-modal>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-modal
            id="problematic_devices"
            size="xl"
            hide-header
            hide-footer
            centered
          >
            <div style="height: 80vh; overflow-y: auto">
              <button
                class="btn btn-light-primary btn-block mb-3"
                @click="$bvModal.hide('problematic_devices')"
              >
                Close
              </button>
              <button
                class="btn btn-primary btn-block mb-3"
                @click="fetchAllLastTransactions"
              >
                Fetch All Last Transactions
              </button>
              <div class="table-responsive mb-0 pb-0">
                <table
                  v-if="problematicDevices.length > 0"
                  class="table table-head-custom table-vertical-center table-head-bg"
                >
                  <thead>
                    <tr class="text-uppercase">
                      <th class="pl-6">Device Name & Tags</th>
                      <th>
                        <span
                          @click="problematicDeviceOrderByLocation"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary':
                                problematicDeviceLocationOrder.isActive,
                            }"
                          >
                            {{ $t('GENERAL.LOCATION') }}</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary':
                                problematicDeviceLocationOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="
                                problematicDeviceLocationOrder.query === 'asc'
                              "
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                      <th
                        v-if="currentActiveModuleComponents?.location.lessors"
                      >
                        <span
                          @click="problematicDeviceOrderByLessor"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary':
                                problematicDeviceLessorOrder.isActive,
                            }"
                          >
                            {{ $t('GENERAL.LESSOR') }}</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary':
                                problematicDeviceLessorOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="
                                problematicDeviceLessorOrder.query === 'asc'
                              "
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                      <th>Imei</th>
                      <th>
                        <span
                          @click="problematicDeviceOrderByServiceMode"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary':
                                problematicDeviceServiceModeOrder.isActive,
                            }"
                          >
                            Service mode</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary':
                                problematicDeviceServiceModeOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="
                                problematicDeviceServiceModeOrder.query ===
                                'asc'
                              "
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                      <th class="text-right">
                        <span
                          @click="problematicDeviceOrderByTime"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary':
                                problematicDeviceTimeOrder.isActive,
                            }"
                          >
                            Last transaction</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary':
                                problematicDeviceTimeOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="problematicDeviceTimeOrder.query === 'asc'"
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(problematicDevice, key) in problematicDevices"
                      :key="key"
                    >
                      <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                        <router-link
                          :to="'/devices/edit/' + problematicDevice.id"
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >{{ problematicDevice.name }}
                        </router-link>
                        <div>
                          <span
                            v-for="(tag, key) in problematicDevice.tags"
                            :key="key"
                            class="label label-sm label-light-primary label-inline mr-2"
                            >{{ tag.name }}</span
                          >
                        </div>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{ problematicDevice.location.title }}</span>
                      </td>
                      <td
                        v-if="currentActiveModuleComponents?.location.lessors"
                        :class="{ 'border-top-0': key === 0 }"
                      >
                        <span v-if="problematicDevice.location.lessor">{{
                          problematicDevice.location.lessor.name
                        }}</span>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span class="text-muted font-weight-500">{{
                          problematicDevice.logger?.imei
                        }}</span>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span
                          v-if="problematicDevice.serviceMode"
                          class="label label-lg label-light-success label-inline"
                          >On</span
                        >
                        <span
                          v-else
                          class="label label-lg label-light-danger label-inline"
                          >Off</span
                        >
                      </td>
                      <td
                        class="text-right"
                        :class="{ 'border-top-0': key === 0 }"
                      >
                        <span :id="'lt_' + key">{{
                          problematicDevice.lastTransactionDateTime
                            | formatDateTime
                        }}</span>
                        <button
                          @click="fetchLastTransaction(problematicDevice)"
                          class="btn btn-light-primary btn-sm ml-2 lt-fetch"
                        >
                          Fetch
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else>
                  <div class="alert alert-primary mb-0" role="alert">
                    Currently no problematic devices!
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-modal
            id="problematic_device_msg"
            size="sm"
            hide-header
            hide-footer
            centered
          >
            <div>
              <button
                class="btn btn-light-primary btn-block mb-3"
                @click="$bvModal.hide('problematic_device_msg')"
              >
                Close
              </button>
              <div>
                LAST PAY MESSAGE:
                <b>{{ problematicDeviceMsg.msg_ts | formatDateTime }}</b>
                <br /><br />
                <small><i>device timestamps:</i></small
                ><br />
                <pre style="background-color: #d3d3d37d">{{
                  problematicDeviceMsg.all_msg
                }}</pre>
              </div>
            </div>
          </b-modal>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-modal
            id="critical_devices"
            size="xl"
            hide-header
            hide-footer
            centered
          >
            <div style="height: 80vh; overflow-y: auto">
              <button
                class="btn btn-light-primary btn-block mb-3"
                @click="$bvModal.hide('critical_devices')"
              >
                Close
              </button>
              <button
                class="btn btn-primary btn-block mb-3"
                @click="fetchAllLastMessages"
              >
                Fetch All Last Messages
              </button>
              <div class="table-responsive mb-0 pb-0">
                <table
                  v-if="criticalDevices.length > 0"
                  class="table table-head-custom table-vertical-center table-head-bg"
                >
                  <thead>
                    <tr class="text-uppercase">
                      <th class="pl-6">Device Name & Tags</th>
                      <th>
                        <span
                          @click="criticalDeviceOrderByLocation"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary':
                                criticalDeviceLocationOrder.isActive,
                            }"
                          >
                            {{ $t('GENERAL.LOCATION') }}</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary':
                                criticalDeviceLocationOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="criticalDeviceLocationOrder.query === 'asc'"
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                      <th
                        v-if="currentActiveModuleComponents?.location.lessors"
                      >
                        <span
                          @click="criticalDeviceOrderByLessor"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary':
                                criticalDeviceLessorOrder.isActive,
                            }"
                          >
                            {{ $t('GENERAL.LESSOR') }}</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary':
                                criticalDeviceLessorOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="criticalDeviceLessorOrder.query === 'asc'"
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                      <th>Imei</th>
                      <th>
                        <span
                          @click="criticalDeviceOrderByServiceMode"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary':
                                criticalDeviceServiceModeOrder.isActive,
                            }"
                          >
                            Service mode</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary':
                                criticalDeviceServiceModeOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="
                                criticalDeviceServiceModeOrder.query === 'asc'
                              "
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                      <th>
                        <span
                          @click="criticalDeviceOrderByTime"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary':
                                problematicDeviceTimeOrder.isActive,
                            }"
                          >
                            Last message</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary':
                                problematicDeviceTimeOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="problematicDeviceTimeOrder.query === 'asc'"
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(criticalDevice, key) in criticalDevices"
                      :key="key"
                    >
                      <td class="pl-6">
                        <router-link
                          :to="'/devices/edit/' + criticalDevice.id"
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >{{ criticalDevice.name }}
                        </router-link>
                        <div>
                          <span
                            v-for="(tag, key) in criticalDevice.tags"
                            :key="key"
                            class="label label-sm label-light-primary label-inline mr-2"
                            >{{ tag.name }}</span
                          >
                        </div>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{ criticalDevice.location.title }}</span>
                      </td>
                      <td
                        v-if="currentActiveModuleComponents.location.lessors"
                        :class="{ 'border-top-0': key === 0 }"
                      >
                        <span v-if="criticalDevice.location.lessor">{{
                          criticalDevice.location.lessor.name
                        }}</span>
                      </td>
                      <td>
                        <span class="text-muted font-weight-500">{{
                          criticalDevice.logger?.imei
                        }}</span>
                      </td>
                      <td>
                        <span
                          v-if="criticalDevice.serviceMode"
                          class="label label-lg label-light-success label-inline"
                          >On</span
                        >
                        <span
                          v-else
                          class="label label-lg label-light-danger label-inline"
                          >Off</span
                        >
                      </td>
                      <td>
                        <span :id="'lm_' + key">{{
                          criticalDevice.lastMessageDateTime | formatDateTime
                        }}</span>
                        <button
                          @click="fetchLastMessage(criticalDevice)"
                          class="btn btn-light-primary ml-2 btn-sm"
                        >
                          Fetch
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else>
                  <div class="alert alert-primary mb-0" role="alert">
                    Currently no critical devices!
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-modal
            id="critical_device_msg"
            size="sm"
            hide-header
            hide-footer
            centered
          >
            <div>
              <b-button
                class="btn btn-light-primary mb-3"
                block
                @click="$bvModal.hide('critical_device_msg')"
                >Close
              </b-button>
              <div>
                LAST MESSAGE:
                <b>{{ criticalDeviceMsg.msg_ts | formatDateTime }}</b>
                <br /><br />
                <small><i>device timestamps:</i></small
                ><br />
                <pre style="background-color: #d3d3d37d">{{
                  criticalDeviceMsg.all_msg
                }}</pre>
              </div>
            </div>
          </b-modal>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-modal
            id="un_fiscalized_bills_modal"
            size="md"
            hide-header
            hide-footer
            centered
          >
            <div>
              <div>
                <div
                  class="alert alert-primary font-weight-bold text-center"
                  role="alert"
                >
                  You have {{ unFiscalizedBillsNumber }} un fiscalized bills
                </div>
                <router-link
                  to="/bills/unfiscalized"
                  class="btn btn-light-primary btn-block"
                  >View
                </router-link>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>

    <UnFiscalBills
      v-if="
        currentUserRole === 'ROLE_ROOT' ||
        currentUserRole === 'ROLE_SUPER_ADMIN'
      "
    ></UnFiscalBills>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import DailyIndexWidget from '@/view/pages/dashboard/_components/daily-index/DailyIndexWidget'
import WeeklyIndexWidget from '@/view/pages/dashboard/_components/weekly-index/WeeklyIndexWidget'
import MonthlyIndexWidget from '@/view/pages/dashboard/_components/monthly-index/MonthlyIndexWidget'
import YearlyIndexWidget from '@/view/pages/dashboard/_components/yearly-index/YearlyIndexWidget'
import CurrentBalance from '@/view/pages/dashboard/_components/current-balance/CurrentBalance'
import CurrentMonthRevenue from '@/view/pages/dashboard/_components/current-month-revenue/CurrentMonthRevenue'
import LastMonthRevenue from '@/view/pages/dashboard/_components/last-month-revenue/LastMonthRevenue'
import DaysInMontyTraffic from '@/view/pages/dashboard/_components/DaysInMontyTraffic'
import DevicesTraffic from '@/view/pages/dashboard/_components/DevicesTraffic'
import Last12MonthTraffic from '@/view/pages/dashboard/_components/Last12MonthTraffic'
import addMonths from 'date-fns/addMonths'
import addDays from 'date-fns/addDays'
import addYears from 'date-fns/addYears'
import { format, subDays } from 'date-fns'
import parse from 'date-fns/parse'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfYear from 'date-fns/startOfYear'
import endOfYear from 'date-fns/endOfYear'
import getDayOfYear from 'date-fns/getDayOfYear'
import differenceInDays from 'date-fns/differenceInDays'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DeviceReportTable from '@/view/content/components/device-report-table/DeviceReportTable'
import DeviceReportTableKn from '@/view/content/components/device-report-table-kn/DeviceReportTableKn'
import DailyHistory from '@/view/pages/dashboard/_components/daily-history/DailyHistory'
import WeeklyHistory from '@/view/pages/dashboard/_components/weekly-history/WeeklyHistory'
import MonthlyHistory from '@/view/pages/dashboard/_components/monthly-history/MonthlyHistory'
import 'swiper/css/swiper.css'
import isMonday from 'date-fns/isMonday'
import isTuesday from 'date-fns/isTuesday'
import isWednesday from 'date-fns/isWednesday'
import isThursday from 'date-fns/isThursday'
import isFriday from 'date-fns/isFriday'
import isSaturday from 'date-fns/isSaturday'
import isSunday from 'date-fns/isSunday'
import add from 'date-fns/add'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import axios from 'axios'
import UnFiscalBills from '../content/components/unfiscal-bills/UnFiscalBills'
import { getPastMonthFirstDay } from '@/core/helpers/helpers'
// import error from "@/view/pages/error/Error.vue";

export default {
  name: 'dashboard',
  components: {
    Swiper,
    SwiperSlide,
    DailyIndexWidget,
    WeeklyIndexWidget,
    MonthlyIndexWidget,
    YearlyIndexWidget,
    CurrentBalance,
    CurrentMonthRevenue,
    LastMonthRevenue,
    DatePicker,
    DeviceReportTable,
    DeviceReportTableKn,
    DailyHistory,
    WeeklyHistory,
    MonthlyHistory,
    DaysInMontyTraffic,
    DevicesTraffic,
    Last12MonthTraffic,
    UnFiscalBills,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      today: new Date(),
      currentMonth: {
        month: null,
        firstDay: null,
        lastDay: null,
      },
      lastMonth: {
        month: null,
        firstDay: null,
        lastDay: null,
      },

      // date picker
      range: {
        start: subDays(new Date(), 1).setHours(24, 0, 0),
        end: new Date().setHours(23, 59, 59),
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      maxDate: new Date(),
      minDate: null,

      // devices
      devices: [],
      criticalDevices: [],
      isCriticalDevicesLoaded: false,
      problematicDevices: [],
      isProblematicDevicesLoaded: false,
      isProblematicDevicesTimeLoaded: false,
      isCriticalDevicesTimeLoaded: false,

      todayTransactions: [],
      todayMinusSevenDaysTransactions: [],

      last30DaysTraffic: [],
      isLas30DaysTrafficLoaded: false,

      devicesTrafficLast30Days: [],
      isDevicesTrafficLast30DaysLoaded: false,

      devicesLast12MonthTraffic: [],
      isDevicesLast12MonthTraffic: false,

      dailyIndex: {
        today: {
          date: {
            start: {
              original: null,
              formatted: null,
            },
            end: {
              original: null,
              formatted: null,
            },
            current: {
              original: null,
              formatted: null,
            },
          },
          total: null,
          transactions: [],
          isLoaded: false,
        },
        todayMinusSevenDays: {
          date: {
            start: {
              original: null,
              formatted: null,
            },
            end: {
              original: null,
              formatted: null,
            },
            current: {
              original: null,
              formatted: null,
            },
          },
          total: null,
          transactions: [],
          isLoaded: false,
        },
        todayMinus365Days: {
          date: {
            start: {
              original: null,
              formatted: null,
            },
            end: {
              original: null,
              formatted: null,
            },
            current: {
              original: null,
              formatted: null,
            },
          },
          total: null,
          transactions: [],
          isLoaded: false,
        },
      },
      dailyIndexPreviousYear: {
        today: {
          date: {
            start: {
              original: null,
              formatted: null,
            },
            end: {
              original: null,
              formatted: null,
            },
            current: {
              original: null,
              formatted: null,
            },
          },
          total: null,
          transactions: [],
          isLoaded: false,
        },
        todayMinusSevenDays: {
          date: {
            start: {
              original: null,
              formatted: null,
            },
            end: {
              original: null,
              formatted: null,
            },
            current: {
              original: null,
              formatted: null,
            },
          },
          total: null,
          transactions: [],
          isLoaded: false,
        },
        hybrid: {
          today: {
            date: {
              startTime: {
                original: null,
                formatted: null,
              },
              endTime: {
                original: null,
                formatted: null,
              },
              currentTime: {
                original: null,
                formatted: null,
              },
            },
            total: null,
            transactions: [],
            isLoaded: false,
          },
          todayMinusSevenDays: {
            date: {
              startTime: {
                original: null,
                formatted: null,
              },
              endTime: {
                original: null,
                formatted: null,
              },
              currentTime: {
                original: null,
                formatted: null,
              },
            },
            total: null,
            transactions: [],
            isLoaded: false,
          },
        },
        isHybrid: false,
      },

      weeklyIndex: {
        normal: {
          currentWeek: {
            date: {
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
          pastWeek: {
            date: {
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
        },
        hybrid: {
          currentWeek: {
            date: {
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
          pastWeek: {
            date: {
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
        },
      },
      weeklyIndexPreviousYea: {
        normal: {
          currentWeek: {
            date: {
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
          pastWeek: {
            date: {
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
        },
        hybrid: {
          currentWeek: {
            date: {
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
          pastWeek: {
            date: {
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
        },
        isHybrid: false,
      },

      monthlyIndex: {
        normal: {
          currentMonth: {
            date: {
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
          pastMonth: {
            date: {
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
        },
        isHybrid: false,
      },
      monthlyIndexPreviousYea: {
        normal: {
          currentMonth: {
            date: {
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
          pastMonth: {
            date: {
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: null,
            days: [],
            transactions: [],
            isLoaded: false,
          },
        },
        isHybrid: false,
      },

      yearlyIndex: {
        normal: {
          currentYear: {
            date: {
              currentDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              firstDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
              lastDay: {
                start: {
                  original: null,
                  formatted: null,
                },
                end: {
                  original: null,
                  formatted: null,
                },
                current: {
                  original: null,
                  formatted: null,
                },
              },
            },
            total: 0,
            days: [],
            transactions: [],
            isLoaded: false,
          },
          pastYear: {
            date: {},
            total: 0,
            days: [],
            transactions: [],
            isLoaded: false,
          },
        },
        hybrid: {},
        isHybrid: false,
      },

      isGenerateReportInProgress: false,
      isGenerateReportLoaded: false,
      showEuroReport: false,
      reportDevices: [],

      isDailyIndexHistoryShow: false,
      isWeeklyIndexShow: false,
      isMonthlyIndexShow: false,
      isYearlyIndexShow: false,

      isDailyIndexHybridOn: false,
      isWeeklyIndexHybridOn: false,
      isMonthlyIndexHybridOn: true,
      isYearlyIndexHybridOn: false,

      pT: {
        lastMontyTransactions: [],
        revenueLastMonth: 0,
        revenueTillToday: 0,
        revenueFromToday: 0,
        total: 0,
        isLoaded: false,
      },

      problematicDeviceMsg: {
        msg_ts: null,
        msg_all: null,
      },
      criticalDeviceMsg: {
        msg_ts: null,
        msg_all: null,
      },

      totalll: 0,

      isAllReady: true,

      // problematic devices order
      problematicDeviceLocationOrder: {
        query: 'asc',
        isActive: false,
      },
      problematicDeviceLessorOrder: {
        query: 'asc',
        isActive: false,
      },
      problematicDeviceServiceModeOrder: {
        query: 'asc',
        isActive: false,
      },
      problematicDeviceTimeOrder: {
        query: 'asc',
        isActive: false,
      },

      // critical devices order
      criticalDeviceLocationOrder: {
        query: 'asc',
        isActive: false,
      },
      criticalDeviceLessorOrder: {
        query: 'asc',
        isActive: false,
      },
      criticalDeviceServiceModeOrder: {
        query: 'asc',
        isActive: false,
      },
      criticalDeviceTimeOrder: {
        query: 'asc',
        isActive: false,
      },
      unFiscalizedBillsNumber: 0,
      isUnFiscalizedBillsLoaded: false,

      pdfKnUrl: `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/pdf/company-devices-revenue-kn`,
      pdfEurUrl: `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/pdf/company-devices-revenue`,
      pdfDownloadUrl: `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/pdf/company-devices-revenue`,
      xlsxKnUrl: `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/company-devices-revenue-kn`,
      xlsxEurUrl: `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/company-devices-revenue`,
      xlsxDownloadUrl: `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/company-devices-revenue`,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyOib',
      'currentUserCompanyModule',
      'currentActiveModuleComponents',
      'currentUserPersonalInfo',
    ]),
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('MAIN_MENU.DASHBOARD') }])

    // dailyIndex
    this.dailyIndex.today.date.current.original = this.today
    this.dailyIndex.today.date.current.formatted = format(
      this.dailyIndex.today.date.current.original,
      "yyyy-MM-dd'T'HH:mm"
    )
    this.dailyIndex.today.date.start.formatted = `${this.dailyIndex.today.date.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.dailyIndex.today.date.start.original = parse(
      this.dailyIndex.today.date.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.dailyIndex.today.date.end.formatted = `${this.dailyIndex.today.date.current.formatted.slice(
      0,
      10
    )}T23:59`
    this.dailyIndex.today.date.end.original = parse(
      this.dailyIndex.today.date.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    this.dailyIndex.todayMinusSevenDays.date.current.original = addDays(
      this.dailyIndex.today.date.current.original,
      -7
    )
    this.dailyIndex.todayMinusSevenDays.date.current.formatted = format(
      this.dailyIndex.todayMinusSevenDays.date.current.original,
      "yyyy-MM-dd'T'HH:mm"
    )
    this.dailyIndex.todayMinusSevenDays.date.start.formatted = `${this.dailyIndex.todayMinusSevenDays.date.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.dailyIndex.todayMinusSevenDays.date.start.original = parse(
      this.dailyIndex.todayMinusSevenDays.date.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.dailyIndex.todayMinusSevenDays.date.end.formatted = `${this.dailyIndex.todayMinusSevenDays.date.current.formatted.slice(
      0,
      10
    )}T23:59`
    this.dailyIndex.todayMinusSevenDays.date.end.original = parse(
      this.dailyIndex.todayMinusSevenDays.date.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    this.dailyIndex.todayMinus365Days.date.current.original = addDays(
      this.dailyIndex.today.date.current.original,
      -365
    )
    this.dailyIndex.todayMinus365Days.date.current.formatted = format(
      this.dailyIndex.todayMinus365Days.date.current.original,
      "yyyy-MM-dd'T'HH:mm"
    )
    this.dailyIndex.todayMinus365Days.date.start.formatted = `${this.dailyIndex.todayMinus365Days.date.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.dailyIndex.todayMinus365Days.date.start.original = parse(
      this.dailyIndex.todayMinus365Days.date.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.dailyIndex.todayMinus365Days.date.end.formatted = `${this.dailyIndex.todayMinus365Days.date.current.formatted.slice(
      0,
      10
    )}T23:59`
    this.dailyIndex.todayMinus365Days.date.end.original = parse(
      this.dailyIndex.todayMinus365Days.date.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    // dailyIndexPreviousYear
    this.dailyIndexPreviousYear.today.date.current.original = addYears(
      this.today,
      -1
    )
    this.dailyIndexPreviousYear.today.date.current.formatted = format(
      this.dailyIndexPreviousYear.today.date.current.original,
      "yyyy-MM-dd'T'HH:mm"
    )
    this.dailyIndexPreviousYear.today.date.start.formatted = `${this.dailyIndexPreviousYear.today.date.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.dailyIndexPreviousYear.today.date.start.original = parse(
      this.dailyIndexPreviousYear.today.date.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.dailyIndexPreviousYear.today.date.end.formatted = `${this.dailyIndexPreviousYear.today.date.current.formatted.slice(
      0,
      10
    )}T23:59`
    this.dailyIndexPreviousYear.today.date.end.original = parse(
      this.dailyIndexPreviousYear.today.date.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    this.dailyIndexPreviousYear.todayMinusSevenDays.date.current.original =
      addDays(this.dailyIndexPreviousYear.today.date.current.original, -7)
    this.dailyIndexPreviousYear.todayMinusSevenDays.date.current.formatted =
      format(
        this.dailyIndexPreviousYear.todayMinusSevenDays.date.current.original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.dailyIndexPreviousYear.todayMinusSevenDays.date.start.formatted = `${this.dailyIndexPreviousYear.todayMinusSevenDays.date.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.dailyIndexPreviousYear.todayMinusSevenDays.date.start.original = parse(
      this.dailyIndexPreviousYear.todayMinusSevenDays.date.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.dailyIndexPreviousYear.todayMinusSevenDays.date.end.formatted = `${this.dailyIndexPreviousYear.todayMinusSevenDays.date.current.formatted.slice(
      0,
      10
    )}T23:59`
    this.dailyIndexPreviousYear.todayMinusSevenDays.date.end.original = parse(
      this.dailyIndexPreviousYear.todayMinusSevenDays.date.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    // weeklyIndex
    this.weeklyIndex.normal.currentWeek.days = eachDayOfInterval({
      start: startOfWeek(this.today, { weekStartsOn: 1 }),
      end: this.today,
    })
    this.weeklyIndex.normal.currentWeek.date.currentDay.current.original =
      this.today
    this.weeklyIndex.normal.currentWeek.date.currentDay.current.formatted =
      format(
        this.weeklyIndex.normal.currentWeek.date.currentDay.current.original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.weeklyIndex.normal.currentWeek.date.currentDay.start.formatted = `${this.weeklyIndex.normal.currentWeek.date.currentDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.weeklyIndex.normal.currentWeek.date.currentDay.start.original = parse(
      this.weeklyIndex.normal.currentWeek.date.currentDay.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.weeklyIndex.normal.currentWeek.date.currentDay.end.formatted = `${this.weeklyIndex.normal.currentWeek.date.currentDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.weeklyIndex.normal.currentWeek.date.currentDay.end.original = parse(
      this.weeklyIndex.normal.currentWeek.date.currentDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    this.weeklyIndex.normal.currentWeek.date.firstDay.current.original =
      startOfWeek(this.today, { weekStartsOn: 1 })
    this.weeklyIndex.normal.currentWeek.date.firstDay.current.formatted =
      format(
        this.weeklyIndex.normal.currentWeek.date.firstDay.current.original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.weeklyIndex.normal.currentWeek.date.firstDay.start.formatted = `${this.weeklyIndex.normal.currentWeek.date.firstDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.weeklyIndex.normal.currentWeek.date.firstDay.start.original = parse(
      this.weeklyIndex.normal.currentWeek.date.firstDay.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.weeklyIndex.normal.currentWeek.date.firstDay.end.formatted = `${this.weeklyIndex.normal.currentWeek.date.firstDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.weeklyIndex.normal.currentWeek.date.firstDay.end.original = parse(
      this.weeklyIndex.normal.currentWeek.date.firstDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    this.weeklyIndex.normal.currentWeek.date.lastDay.current.original =
      endOfWeek(this.today, { weekStartsOn: 1 })
    this.weeklyIndex.normal.currentWeek.date.lastDay.current.formatted = format(
      this.weeklyIndex.normal.currentWeek.date.lastDay.current.original,
      "yyyy-MM-dd'T'HH:mm"
    )
    this.weeklyIndex.normal.currentWeek.date.lastDay.start.formatted = `${this.weeklyIndex.normal.currentWeek.date.lastDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.weeklyIndex.normal.currentWeek.date.lastDay.start.original = parse(
      this.weeklyIndex.normal.currentWeek.date.lastDay.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.weeklyIndex.normal.currentWeek.date.lastDay.end.formatted = `${this.weeklyIndex.normal.currentWeek.date.lastDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.weeklyIndex.normal.currentWeek.date.lastDay.end.original = parse(
      this.weeklyIndex.normal.currentWeek.date.lastDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    // weeklyIndexPreviousYea
    this.weeklyIndexPreviousYea.normal.currentWeek.days = eachDayOfInterval({
      start: startOfWeek(addYears(this.today, -1), { weekStartsOn: 1 }),
      end: this.today,
    })
    this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.current.original =
      addYears(this.today, -1)
    this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.current.formatted =
      format(
        this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.current
          .original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.start.formatted = `${this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.start.original =
      parse(
        this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.start
          .formatted,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      )
    this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.end.formatted = `${this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.end.original =
      parse(
        this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay.end
          .formatted,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      )

    this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.current.original =
      startOfWeek(addYears(this.today, -1), { weekStartsOn: 1 })
    this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.current.formatted =
      format(
        this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.current
          .original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.start.formatted = `${this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.start.original =
      parse(
        this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.start
          .formatted,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      )
    this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.end.formatted = `${this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.end.original =
      parse(
        this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.end
          .formatted,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      )

    this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.current.original =
      endOfWeek(addYears(this.today, -1), { weekStartsOn: 1 })
    this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.current.formatted =
      format(
        this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.current
          .original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.start.formatted = `${this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.start.original =
      parse(
        this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.start
          .formatted,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      )
    this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.end.formatted = `${this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.end.original =
      parse(
        this.weeklyIndexPreviousYea.normal.currentWeek.date.lastDay.end
          .formatted,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      )

    // monthlyIndex
    this.monthlyIndex.normal.currentMonth.days = eachDayOfInterval({
      start: startOfMonth(this.today),
      end: endOfMonth(this.today),
    })
    this.monthlyIndex.normal.currentMonth.date.currentDay.current.original =
      this.today
    this.monthlyIndex.normal.currentMonth.date.currentDay.current.formatted =
      format(
        this.monthlyIndex.normal.currentMonth.date.currentDay.current.original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.monthlyIndex.normal.currentMonth.date.currentDay.start.formatted = `${this.monthlyIndex.normal.currentMonth.date.currentDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.monthlyIndex.normal.currentMonth.date.currentDay.start.original =
      parse(
        this.monthlyIndex.normal.currentMonth.date.currentDay.start.formatted,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      )
    this.monthlyIndex.normal.currentMonth.date.currentDay.end.formatted = `${this.monthlyIndex.normal.currentMonth.date.currentDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.monthlyIndex.normal.currentMonth.date.currentDay.end.original = parse(
      this.monthlyIndex.normal.currentMonth.date.currentDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    this.monthlyIndex.normal.currentMonth.date.firstDay.current.original =
      startOfMonth(this.today)
    this.monthlyIndex.normal.currentMonth.date.firstDay.current.formatted =
      format(
        this.monthlyIndex.normal.currentMonth.date.firstDay.current.original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.monthlyIndex.normal.currentMonth.date.firstDay.start.formatted = `${this.monthlyIndex.normal.currentMonth.date.firstDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.monthlyIndex.normal.currentMonth.date.firstDay.start.original = parse(
      this.monthlyIndex.normal.currentMonth.date.firstDay.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.monthlyIndex.normal.currentMonth.date.firstDay.end.formatted = `${this.monthlyIndex.normal.currentMonth.date.firstDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.monthlyIndex.normal.currentMonth.date.firstDay.end.original = parse(
      this.monthlyIndex.normal.currentMonth.date.firstDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    this.monthlyIndex.normal.currentMonth.date.lastDay.current.original =
      endOfMonth(this.today)
    this.monthlyIndex.normal.currentMonth.date.lastDay.current.formatted =
      format(
        this.monthlyIndex.normal.currentMonth.date.lastDay.current.original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.monthlyIndex.normal.currentMonth.date.lastDay.start.formatted = `${this.monthlyIndex.normal.currentMonth.date.lastDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.monthlyIndex.normal.currentMonth.date.lastDay.start.original = parse(
      this.monthlyIndex.normal.currentMonth.date.lastDay.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.monthlyIndex.normal.currentMonth.date.lastDay.end.formatted = `${this.monthlyIndex.normal.currentMonth.date.lastDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.monthlyIndex.normal.currentMonth.date.lastDay.end.original = parse(
      this.monthlyIndex.normal.currentMonth.date.lastDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    // monthlyIndex pastMonth
    this.monthlyIndex.normal.pastMonth.days = eachDayOfInterval({
      start: startOfMonth(addMonths(this.today, -1)),
      end: endOfMonth(addMonths(this.today, -1)),
    })
    this.monthlyIndex.normal.pastMonth.date.currentDay.current.original =
      addDays(this.today, -31)
    this.monthlyIndex.normal.pastMonth.date.currentDay.current.formatted =
      format(
        this.monthlyIndex.normal.pastMonth.date.currentDay.current.original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.monthlyIndex.normal.pastMonth.date.currentDay.start.formatted = `${this.monthlyIndex.normal.pastMonth.date.currentDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.monthlyIndex.normal.pastMonth.date.currentDay.start.original = parse(
      this.monthlyIndex.normal.pastMonth.date.currentDay.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.monthlyIndex.normal.pastMonth.date.currentDay.end.formatted = `${this.monthlyIndex.normal.pastMonth.date.currentDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.monthlyIndex.normal.pastMonth.date.currentDay.end.original = parse(
      this.monthlyIndex.normal.pastMonth.date.currentDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    this.monthlyIndex.normal.pastMonth.date.firstDay.current.original =
      startOfMonth(this.monthlyIndex.normal.pastMonth.days[0]) // todo
    this.monthlyIndex.normal.pastMonth.date.firstDay.current.formatted = format(
      this.monthlyIndex.normal.pastMonth.date.firstDay.current.original,
      "yyyy-MM-dd'T'HH:mm"
    ) // todo
    this.monthlyIndex.normal.pastMonth.date.firstDay.start.formatted = `${this.monthlyIndex.normal.pastMonth.date.firstDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.monthlyIndex.normal.pastMonth.date.firstDay.start.original = parse(
      this.monthlyIndex.normal.pastMonth.date.firstDay.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.monthlyIndex.normal.pastMonth.date.firstDay.end.formatted = `${this.monthlyIndex.normal.pastMonth.date.firstDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.monthlyIndex.normal.pastMonth.date.firstDay.end.original = parse(
      this.monthlyIndex.normal.pastMonth.date.firstDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    this.monthlyIndex.normal.pastMonth.date.lastDay.current.original =
      endOfMonth(this.monthlyIndex.normal.pastMonth.days[0]) // todo
    this.monthlyIndex.normal.pastMonth.date.lastDay.current.formatted = format(
      this.monthlyIndex.normal.pastMonth.date.lastDay.current.original,
      "yyyy-MM-dd'T'HH:mm"
    ) // todo
    this.monthlyIndex.normal.pastMonth.date.lastDay.start.formatted = `${this.monthlyIndex.normal.pastMonth.date.lastDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.monthlyIndex.normal.pastMonth.date.lastDay.start.original = parse(
      this.monthlyIndex.normal.pastMonth.date.lastDay.start.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )
    this.monthlyIndex.normal.pastMonth.date.lastDay.end.formatted = `${this.monthlyIndex.normal.pastMonth.date.lastDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.monthlyIndex.normal.pastMonth.date.lastDay.end.original = parse(
      this.monthlyIndex.normal.pastMonth.date.lastDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    // monthlyIndexPreviousYea
    this.monthlyIndexPreviousYea.normal.currentMonth.days = eachDayOfInterval({
      start: startOfMonth(addYears(this.today, -1)),
      end: endOfMonth(addYears(this.today, -1)),
    })
    this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.current.original =
      addYears(this.today, -1)
    this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.current.formatted =
      format(
        this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.current
          .original,
        "yyyy-MM-dd'T'HH:mm"
      )
    this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.start.formatted = `${this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.current.formatted.slice(
      0,
      10
    )}T00:00`
    this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.start.original =
      parse(
        this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.start
          .formatted,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      )
    this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.end.formatted = `${this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.end.original =
      parse(
        this.monthlyIndexPreviousYea.normal.currentMonth.date.currentDay.end
          .formatted,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      )

    // yearlyIndex
    this.yearlyIndex.normal.currentYear.days = eachDayOfInterval({
      start: startOfYear(this.today),
      end: endOfYear(this.today),
    })
    this.yearlyIndex.normal.currentYear.date.firstDay.start.original =
      this.yearlyIndex.normal.currentYear.days[0]
    this.yearlyIndex.normal.currentYear.date.firstDay.start.formatted = format(
      this.yearlyIndex.normal.currentYear.date.firstDay.start.original,
      "yyyy-MM-dd'T'HH:mm"
    )
    this.yearlyIndex.normal.currentYear.date.lastDay.start.original =
      this.yearlyIndex.normal.currentYear.days[
        this.yearlyIndex.normal.currentYear.days.length - 1
      ]
    this.yearlyIndex.normal.currentYear.date.lastDay.start.formatted = format(
      this.yearlyIndex.normal.currentYear.date.lastDay.start.original,
      "yyyy-MM-dd'T'HH:mm"
    )
    this.yearlyIndex.normal.currentYear.date.lastDay.end.formatted = `${this.yearlyIndex.normal.currentYear.date.lastDay.start.formatted.slice(
      0,
      10
    )}T23:59`
    this.yearlyIndex.normal.currentYear.date.lastDay.end.original = parse(
      this.yearlyIndex.normal.currentYear.date.lastDay.end.formatted,
      "yyyy-MM-dd'T'HH:mm",
      new Date()
    )

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER' ||
      this.currentUserRole === 'ROLE_JUNIOR'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&pagination=false&new=1&select=flags,timestamps`
      ).then(({ data }) => {
        this.devices = [...data['hydra:member']]

        this.devices.forEach((device) => {
          if (device.isProblematic) this.problematicDevices.push(device)
          if (device.isCritical) this.criticalDevices.push(device)
        })

        this.problematicDevices.forEach((_, key) => {
          this.problematicDevices[key]['lastTransactionDateTime'] = ''
        })

        this.criticalDevices.forEach((_, key) => {
          this.criticalDevices[key]['lastMessageDateTime'] = ''
        })

        this.calculateDailyIndex()
      })
    } else if (this.currentUserRole === 'ROLE_WATCHER') {
      ApiService.get(
        'users',
        `?email=${this.currentUserPersonalInfo.email}`
      ).then(({ data }) => {
        const userLocations = data['hydra:member'][0]['locations']
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&location.id=${userLocations[0].id}`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]

          this.devices.forEach((device) => {
            if (device.isProblematic) this.problematicDevices.push(device)
            if (device.isCritical) this.criticalDevices.push(device)
          })

          this.problematicDevices.forEach((_, key) => {
            this.problematicDevices[key]['lastTransactionDateTime'] = ''
          })

          this.criticalDevices.forEach((_, key) => {
            this.criticalDevices[key]['lastMessageDateTime'] = ''
          })

          this.calculateDailyIndex()
        })
      })
    }

    axios
      .get(
        `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?oib=${this.currentUserCompanyOib}&isFiscal=false&order[createdAt]=desc&pagination=false`
      )
      .then(({ data }) => {
        this.unFiscalizedBillsNumber = data['hydra:totalItems']
        this.isUnFiscalizedBillsLoaded = true

        if (this.unFiscalizedBillsNumber > 0) {
          this.$root.$emit('bv::show::modal', 'un_fiscalized_bills_modal')
        }
      })
  },
  methods: {
    async getCompanyDailyRevenue(companyOib, after, before, itemsPerPage) {
      try {
        return await ApiService.get(
          'company_transactions',
          `?oib=${companyOib}&date[after]=${after}&date[before]=${before}&itemsPerPage=${itemsPerPage}`
        )
      } catch (e) {
        throw new Error(e)
      }
    },
    calculateDailyIndex() {
      if (
        this.currentUserRole === 'ROLE_ADMIN' ||
        this.currentUserRole === 'ROLE_WORKER'
      ) {
        ApiService.get(
          'transactions',
          `?oib=${this.currentUserCompanyOib}&createdAt[after]=${this.dailyIndex.today.date.start.formatted}&createdAt[before]=${this.dailyIndex.today.date.current.formatted}&pagination=false`
        ).then(({ data }) => {
          this.dailyIndex.today.transactions = [...data['hydra:member']]
          this.dailyIndex.today.transactions.forEach((transaction) => {
            this.dailyIndex.today.total += parseFloat(transaction.amount)
          })
          this.dailyIndex.today.isLoaded = true

          ApiService.get(
            'transactions',
            `?oib=${this.currentUserCompanyOib}&createdAt[after]=${this.dailyIndex.todayMinusSevenDays.date.start.formatted}&createdAt[before]=${this.dailyIndex.todayMinusSevenDays.date.current.formatted}&pagination=false`
          ).then(({ data }) => {
            this.dailyIndex.todayMinusSevenDays.transactions = [
              ...data['hydra:member'],
            ]
            this.dailyIndex.todayMinusSevenDays.transactions.forEach(
              (transaction) => {
                this.dailyIndex.todayMinusSevenDays.total += parseFloat(
                  transaction.amount
                )
              }
            )
            this.dailyIndex.todayMinusSevenDays.isLoaded = true

            ApiService.get(
              'transactions',
              `?oib=${this.currentUserCompanyOib}&createdAt[after]=${this.dailyIndex.todayMinus365Days.date.start.formatted}&createdAt[before]=${this.dailyIndex.todayMinus365Days.date.current.formatted}&pagination=false`
            ).then(({ data }) => {
              this.dailyIndex.todayMinus365Days.transactions = [
                ...data['hydra:member'],
              ]
              this.dailyIndex.todayMinus365Days.transactions.forEach(
                (transaction) => {
                  this.dailyIndex.todayMinus365Days.total += parseFloat(
                    transaction.amount
                  )
                }
              )
              this.dailyIndex.todayMinus365Days.isLoaded = true

              this.calculateWeeklyIndex()
            })
          })
        })
      } else if (this.currentUserRole === 'ROLE_WATCHER') {
        ApiService.get(
          'users',
          `?email=${this.currentUserPersonalInfo.email}`
        ).then(({ data }) => {
          const userLocations = data['hydra:member'][0]['locations']
          ApiService.get(
            'transactions',
            `?oib=${this.currentUserCompanyOib}&location=${userLocations[0]['@id']}&createdAt[after]=${this.dailyIndex.today.date.start.formatted}&createdAt[before]=${this.dailyIndex.today.date.current.formatted}&pagination=false`
          ).then(({ data }) => {
            this.dailyIndex.today.transactions = [...data['hydra:member']]
            this.dailyIndex.today.transactions.forEach((transaction) => {
              this.dailyIndex.today.total += parseFloat(transaction.amount)
            })
            this.dailyIndex.today.isLoaded = true

            ApiService.get(
              'transactions',
              `?oib=${this.currentUserCompanyOib}&location=${userLocations[0]['@id']}&createdAt[after]=${this.dailyIndex.todayMinusSevenDays.date.start.formatted}&createdAt[before]=${this.dailyIndex.todayMinusSevenDays.date.current.formatted}&pagination=false`
            ).then(({ data }) => {
              this.dailyIndex.todayMinusSevenDays.transactions = [
                ...data['hydra:member'],
              ]
              this.dailyIndex.todayMinusSevenDays.transactions.forEach(
                (transaction) => {
                  this.dailyIndex.todayMinusSevenDays.total += parseFloat(
                    transaction.amount
                  )
                }
              )
              this.dailyIndex.todayMinusSevenDays.isLoaded = true

              ApiService.get(
                'transactions',
                `?oib=${this.currentUserCompanyOib}&location=${userLocations[0]['@id']}&createdAt[after]=${this.dailyIndex.todayMinus365Days.date.start.formatted}&createdAt[before]=${this.dailyIndex.todayMinus365Days.date.current.formatted}&pagination=false`
              ).then(({ data }) => {
                this.dailyIndex.todayMinus365Days.transactions = [
                  ...data['hydra:member'],
                ]
                this.dailyIndex.todayMinus365Days.transactions.forEach(
                  (transaction) => {
                    this.dailyIndex.todayMinus365Days.total += parseFloat(
                      transaction.amount
                    )
                  }
                )
                this.dailyIndex.todayMinus365Days.isLoaded = true

                // this.calculateWeeklyIndex()
              })
            })
          })
        })
      }
    },
    calculateWeeklyIndex() {
      const currentWeekFirstDay = startOfWeek(new Date(), { weekStartsOn: 1 })
      const currentWeekCurrentDay = new Date()

      const pastWeekFirstDay = startOfWeek(addDays(new Date(), -7), {
        weekStartsOn: 1,
      })
      const pastWeekCurrentDay = addDays(new Date(), -7)

      // current week
      this.getCompanyDailyRevenue(
        this.currentUserCompanyOib,
        format(currentWeekFirstDay, 'yyyy-MM-dd'),
        format(currentWeekCurrentDay, 'yyyy-MM-dd'),
        '7'
      ).then(({ data }) => {
        this.weeklyIndex.normal.currentWeek.transactions = [
          ...data['hydra:member'],
        ]
        this.weeklyIndex.normal.currentWeek.transactions.map(
          (transaction) =>
            (this.weeklyIndex.normal.currentWeek.total += parseFloat(
              transaction.total
            ))
        )
        this.weeklyIndex.normal.currentWeek.total =
          this.weeklyIndex.normal.currentWeek.total +
          this.dailyIndex.today.total

        this.weeklyIndex.normal.currentWeek.isLoaded = true

        // past Week
        this.getCompanyDailyRevenue(
          this.currentUserCompanyOib,
          format(pastWeekFirstDay, 'yyyy-MM-dd'),
          format(addDays(pastWeekCurrentDay, -1), 'yyyy-MM-dd'),
          '7'
        ).then(({ data }) => {
          this.weeklyIndex.normal.pastWeek.transactions = [
            ...data['hydra:member'],
          ]
          this.weeklyIndex.normal.pastWeek.transactions.map(
            (transaction) =>
              (this.weeklyIndex.normal.pastWeek.total += parseFloat(
                transaction.total
              ))
          )
          this.weeklyIndex.normal.pastWeek.total =
            this.weeklyIndex.normal.pastWeek.total +
            this.dailyIndex.todayMinusSevenDays.total

          this.weeklyIndex.normal.pastWeek.isLoaded = true

          this.getCompanyDailyRevenue(
            this.currentUserCompanyOib,
            format(
              this.weeklyIndexPreviousYea.normal.currentWeek.date.firstDay.start
                .original,
              'yyyy-MM-dd'
            ),
            format(
              addDays(
                this.weeklyIndexPreviousYea.normal.currentWeek.date.currentDay
                  .start.original,
                -1
              ),
              'yyyy-MM-dd'
            ),
            '7'
          ).then(({ data }) => {
            this.weeklyIndexPreviousYea.normal.currentWeek.transactions = [
              ...data['hydra:member'],
            ]
            this.weeklyIndexPreviousYea.normal.currentWeek.transactions.map(
              (transaction) =>
                (this.weeklyIndexPreviousYea.normal.currentWeek.total +=
                  parseFloat(transaction.total))
            )
            this.weeklyIndexPreviousYea.normal.currentWeek.total =
              this.weeklyIndexPreviousYea.normal.currentWeek.total +
              this.dailyIndex.todayMinus365Days.total

            this.weeklyIndexPreviousYea.normal.currentWeek.isLoaded = true

            if (this.currentUserCompanyOib === '05959244936') {
              this.calculateMonthlyIndex('hybrid')
            } else {
              this.calculateMonthlyIndex('normal')
            }
          })
        })
      })
    },
    calculateMonthlyIndex(type) {
      let currenMonthFirstDay = null
      let currentMonthCurrentDay = null
      let currentMonthCurrentDayFormat = null
      let currentDayHoursMinutes = null
      let currentMonthDifferenceInDays = null

      let pastMonthFirstDay = null
      let pastMonthCurrentDay = null

      if (type === 'normal') {
        currenMonthFirstDay = startOfMonth(new Date())
        currentMonthCurrentDay = new Date()

        pastMonthFirstDay = startOfMonth(addMonths(new Date(), -1))
        pastMonthCurrentDay = addMonths(new Date(), -1)

        this.monthlyIndex.isHybrid = false
      } else {
        // hybrid
        currenMonthFirstDay = startOfMonth(new Date())
        currentMonthCurrentDay = new Date()
        currentMonthCurrentDayFormat = format(
          currentMonthCurrentDay,
          "yyyy-MM-dd'T'HH:mm"
        )
        currentDayHoursMinutes = currentMonthCurrentDayFormat.slice(11, 16)

        pastMonthFirstDay = getPastMonthFirstDay(new Date())

        currentMonthDifferenceInDays = differenceInDays(
          currentMonthCurrentDay,
          currenMonthFirstDay
        )
        pastMonthCurrentDay = addDays(
          pastMonthFirstDay,
          +currentMonthDifferenceInDays
        )

        ApiService.get(
          'company_transactions',
          `?oib=${this.currentUserCompanyOib}&date[after]=${format(
            pastMonthFirstDay,
            'yyyy-MM-dd'
          )}&date[before]=${format(
            addDays(pastMonthCurrentDay, -1),
            'yyyy-MM-dd'
          )}&pagination=false`
        ).then(({ data }) => {
          this.totalll = 0
          data['hydra:member'].forEach((day) => {
            this.totalll += parseFloat(day.total)
          })

          let t1 = format(pastMonthCurrentDay, "yyyy-MM-dd'T'HH:mm:ss")
          let t2 = t1.slice(0, 11)
          t2 = t2 += currentDayHoursMinutes

          ApiService.get(
            'transactions',
            `?oib=${this.currentUserCompanyOib}&createdAt[after]=${t1}&createdAt[before]=${t2}&pagination=false`
          ).then(({ data }) => {
            data['hydra:member'].forEach((transaction) => {
              this.totalll += parseFloat(transaction.amount)
            })

            setTimeout(() => {
              if (type !== 'normal') {
                this.monthlyIndex.normal.pastMonth.total = this.totalll
                this.monthlyIndex.normal.pastMonth.isLoaded = true
                this.monthlyIndex.isHybrid = true
              }
            }, 2000)
          })
        })
      }

      this.monthlyIndex.normal.currentMonth.transactions = []
      this.monthlyIndex.normal.currentMonth.total = 0
      this.monthlyIndex.normal.currentMonth.isLoaded = false

      this.monthlyIndex.normal.pastMonth.transactions = []
      this.monthlyIndex.normal.pastMonth.total = 0
      this.monthlyIndex.normal.pastMonth.isLoaded = false

      // current month
      this.getCompanyDailyRevenue(
        this.currentUserCompanyOib,
        format(currenMonthFirstDay, 'yyyy-MM-dd'),
        format(currentMonthCurrentDay, 'yyyy-MM-dd'),
        '31'
      ).then(({ data }) => {
        this.monthlyIndex.normal.currentMonth.transactions = [
          ...data['hydra:member'],
        ]
        this.monthlyIndex.normal.currentMonth.transactions.map(
          (transaction) =>
            (this.monthlyIndex.normal.currentMonth.total += parseFloat(
              transaction.total
            ))
        )
        this.monthlyIndex.normal.currentMonth.total =
          this.monthlyIndex.normal.currentMonth.total +
          this.dailyIndex.today.total

        this.monthlyIndex.normal.currentMonth.isLoaded = true

        // past month
        this.getCompanyDailyRevenue(
          this.currentUserCompanyOib,
          format(pastMonthFirstDay, 'yyyy-MM-dd'),
          format(addDays(pastMonthCurrentDay, -1), 'yyyy-MM-dd'),
          '31'
        ).then(({ data }) => {
          this.monthlyIndex.normal.pastMonth.transactions = [
            ...data['hydra:member'],
          ]
          this.monthlyIndex.normal.pastMonth.transactions.map(
            (transaction) =>
              (this.monthlyIndex.normal.pastMonth.total += parseFloat(
                transaction.total
              ))
          )

          //
          if (type === 'normal') {
            this.monthlyIndex.normal.pastMonth.total =
              this.monthlyIndex.normal.pastMonth.total +
              this.dailyIndex.today.total
            this.monthlyIndex.normal.pastMonth.isLoaded = true
          }

          // last year this month
          this.getCompanyDailyRevenue(
            this.currentUserCompanyOib,
            format(addYears(currenMonthFirstDay, -1), 'yyyy-MM-dd'),
            format(
              addYears(addDays(currentMonthCurrentDay, -1), -1),
              'yyyy-MM-dd'
            ),
            '31'
          ).then(({ data }) => {
            this.monthlyIndexPreviousYea.normal.currentMonth.transactions = [
              ...data['hydra:member'],
            ]
            this.monthlyIndexPreviousYea.normal.currentMonth.transactions.map(
              (transaction) =>
                (this.monthlyIndexPreviousYea.normal.currentMonth.total +=
                  parseFloat(transaction.total))
            )

            if (type === 'normal') {
              this.monthlyIndexPreviousYea.normal.currentMonth.total =
                this.monthlyIndexPreviousYea.normal.currentMonth.total +
                this.dailyIndex.todayMinus365Days.total
              this.monthlyIndexPreviousYea.normal.currentMonth.isLoaded = true
            }

            this.calculateYearlyIndex()
          })
        })
      })
    },
    calculateYearlyIndex() {
      const currentYearFirstDay = startOfYear(new Date())
      const currentYearCurrentDay = new Date()

      const lastYearFirstDay = addYears(startOfYear(new Date()), -1)
      const lastYearCurrentDay = addYears(new Date(), -1)

      // current year
      this.getCompanyDailyRevenue(
        this.currentUserCompanyOib,
        format(currentYearFirstDay, 'yyyy-MM-dd'),
        format(addDays(currentYearCurrentDay, -1), 'yyyy-MM-dd'),
        getDayOfYear(currentYearCurrentDay)
      ).then(({ data }) => {
        this.yearlyIndex.normal.currentYear.transactions = [
          ...data['hydra:member'],
        ]
        this.yearlyIndex.normal.currentYear.transactions.map(
          (transaction) =>
            (this.yearlyIndex.normal.currentYear.total += parseFloat(
              transaction.total
            ))
        )
        this.yearlyIndex.normal.currentYear.total =
          this.yearlyIndex.normal.currentYear.total +
          this.dailyIndex.today.total

        this.yearlyIndex.normal.currentYear.isLoaded = true

        this.getCompanyDailyRevenue(
          this.currentUserCompanyOib,
          format(lastYearFirstDay, 'yyyy-MM-dd'),
          format(addDays(lastYearCurrentDay, -1), 'yyyy-MM-dd'),
          getDayOfYear(lastYearCurrentDay)
        ).then(({ data }) => {
          this.yearlyIndex.normal.pastYear.transactions = [
            ...data['hydra:member'],
          ]
          this.yearlyIndex.normal.pastYear.transactions.map(
            (transaction) =>
              (this.yearlyIndex.normal.pastYear.total += parseFloat(
                transaction.total
              ))
          )
          this.yearlyIndex.normal.pastYear.total =
            this.yearlyIndex.normal.pastYear.total +
            this.dailyIndex.todayMinus365Days.total

          this.yearlyIndex.normal.pastYear.isLoaded = true

          this.isAllReady = true

          this.calculateLast30DaysRevenue()
        })
      })
    },
    calculateLast30DaysRevenue() {
      const todayMinus30Days = addDays(new Date(), -30)
      const todayMinus30DaysFormat = format(
        todayMinus30Days,
        "yyyy-MM-dd'T'HH:mm"
      )

      ApiService.get(
        'company_transactions',
        `?oib=${this.currentUserCompanyOib}&date[after]=${todayMinus30DaysFormat}&order[date]=desc&pagination=false`
      ).then(({ data }) => {
        data['hydra:member'].forEach((day) => {
          this.last30DaysTraffic.push({
            amount: day.total,
            date: day.date,
          })
        })
        this.isLas30DaysTrafficLoaded = true
      })
    },
    calculateDevicesSaldo() {
      this.isDevicesTrafficLast30DaysLoaded =
        !this.isDevicesTrafficLast30DaysLoaded

      if (this.isDevicesTrafficLast30DaysLoaded) {
        setTimeout(() => {
          document.getElementById('dtl30d').scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          })
        }, 500)
      }
    },
    calculateLast12MonthTraffic() {
      ApiService.get(
        'company_transactions',
        `?company=${this.currentUserCompany}?pagination=false&order[date]=desc`
      ).then(({ data }) => {
        data['hydra:member'].forEach((day) => {
          this.devicesLast12MonthTraffic.push({
            amount: day.total,
            date: new Date(day.date),
          })
        })

        setTimeout(() => {
          this.isDevicesLast12MonthTraffic = true
        }, 500)
      })
    },
    dayClicked({ date }) {
      if (getYear(date) >= 2023) {
        this.minDate = new Date(2023, 0, 1)
        this.maxDate = new Date()
        this.showEuroReport = true
        this.pdfDownloadUrl = this.pdfEurUrl
        this.xlsxDownloadUrl = this.xlsxEurUrl
      } else {
        this.minDate = null
        this.maxDate = new Date(2022, 11, 31)
        this.showEuroReport = false
        this.pdfDownloadUrl = this.pdfKnUrl
        this.xlsxDownloadUrl = this.xlsxKnUrl
      }
    },
    getTransactions(apiUrl) {
      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => {
          console.log(data)

          data['hydra:member'].forEach(
            ({
              imei,
              device_name,
              amount,
              payment_type,
              created_at,
              metadata,
            }) => {
              this.reportDevices.push({
                imei: imei,
                deviceName: device_name,
                location: metadata.location_name,
                lessors: '',
                address: metadata.location,
                tags: metadata.tags ? metadata.tags : [],
                amount: amount,
                paymentType: payment_type,
                createdAt: format(
                  new Date(created_at),
                  'dd.MM.yyyy @ HH:mm:ss'
                ),
              })
            }
          )

          apiUrl = data['hydra:view']['hydra:next']
          if (apiUrl != '' && apiUrl != null) {
            this.getTransactions(process.env.VUE_APP_ROUTE_API_URL + apiUrl)
          } else {
            this.isGenerateReportInProgress = false
            this.isGenerateReportLoaded = true

            setTimeout(() => {
              document.getElementById('generateReportWrapper').scrollIntoView({
                block: 'start',
                behavior: 'smooth',
              })
            }, 1000)
          }
        })
    },
    generateReport(payload) {
      this.reportDevices = []
      this.isGenerateReportInProgress = true
      this.isGenerateReportLoaded = false

      if (payload.start && payload.end) {
        const start = format(new Date(payload.start), "yyyy-MM-dd'T'HH:mm:ss")
        const end = format(new Date(payload.end), "yyyy-MM-dd'T'HH:mm:ss")

        this.minDate = null
        this.maxDate = new Date()

        if (
          this.currentUserRole === 'ROLE_ADMIN' ||
          this.currentUserRole === 'ROLE_WORKER'
        ) {
          const limit = 50000
          let apiUrl =
            process.env.VUE_APP_ROUTE_API_URL +
            `/api/v1/transactions?oib=${this.currentUserCompanyOib}&createdAt[after]=${start}&createdAt[before]=${end}&order[createdAt]=desc` +
            `&pagination=true&itemsPerPage=${limit}&version=new`
          this.getTransactions(apiUrl)

          //
          // ApiService.get(
          //   'transactions',
          //   `?oib=${this.currentUserCompanyOib}&createdAt[after]=${start}&createdAt[before]=${end}&order[createdAt]=desc&pagination=true&itemsPerPage=1000`
          // ).then(({ data }) => {
          //   const pages = data['hydra:view']['hydra:last']
          //   const urlParams = new URLSearchParams(
          //     process.env.VUE_APP_ROUTE_API_URL + pages
          //   )
          //   let pageParam = urlParams.get('page')
          //   if (pageParam === null) pageParam = 1
          //
          //   let promises = []
          //   for (let currentPage = 1; currentPage <= pageParam; currentPage++) {
          //     promises.push(
          //       ApiService.get(
          //         'transactions',
          //         `?oib=${this.currentUserCompanyOib}&createdAt[after]=${start}&createdAt[before]=${end}&order[createdAt]=desc&pagination=true&itemsPerPage=5000&page=${currentPage}`
          //       ).then(({ data }) => {
          //         data['hydra:member'].forEach(
          //           ({
          //             imei,
          //             deviceMeta,
          //             amount,
          //             oldAmount,
          //             paymentType,
          //             createdAt,
          //             locationMeta,
          //           }) => {
          //             this.reportDevices.push({
          //               imei: imei,
          //               deviceName: deviceMeta.name,
          //               location: '',
          //               lessors: '',
          //               address: locationMeta.address
          //                 ? locationMeta.address
          //                 : '',
          //               tags: locationMeta.tags ? locationMeta.tags : [],
          //               amount: amount,
          //               oldAmount: oldAmount,
          //               paymentType: paymentType,
          //               createdAt: format(
          //                 new Date(createdAt),
          //                 'dd.MM.yyyy @ HH:mm:ss'
          //               ),
          //             })
          //           }
          //         )
          //       })
          //     )
          //   }

          //   Promise.all(promises).then(() => {
          //     this.isGenerateReportInProgress = false
          //     this.isGenerateReportLoaded = true
          //
          //     setTimeout(() => {
          //       document
          //         .getElementById('generateReportWrapper')
          //         .scrollIntoView({
          //           block: 'start',
          //           behavior: 'smooth',
          //         })
          //     }, 1000)
          //   })
          // })
        }
      }
    },
    exportAsExcel() {
      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const end = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      axios
        .get(
          `${this.xlsxDownloadUrl}/${this.currentUserCompanyOib}/${from}/${end}`
        )
        .then(({ data }) => {
          if (data.status === 'ok') {
            window.location.replace(data.fileUrl)
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Export success',
            })
          }
        })
    },
    exportAsPDF() {
      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const end = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      window
        .open(
          `${this.pdfDownloadUrl}/${this.currentUserCompanyOib}/${from}/${end}`,
          '_blank'
        )
        .focus()
    },

    showDailyIndexHistory() {
      this.isDailyIndexHistoryShow = true
      this.$root.$emit('bv::show::modal', 'daily_index_modal')
    },
    hideDailyIndexHistory() {
      this.isDailyIndexHistoryShow = false
    },
    showWeeklyIndexHistory() {
      this.isWeeklyIndexShow = true
      this.$root.$emit('bv::show::modal', 'weekly_index_modal')
    },
    hideWeeklyIndexHistory() {
      this.isWeeklyIndexShow = false
    },
    showMonthlyIndexHistory() {
      this.isMonthlyIndexShow = true
      this.$root.$emit('bv::show::modal', 'monthly_index_modal')
    },
    hideMonthlyIndexHistory() {
      this.isMonthlyIndexShow = false
    },
    showYearlyIndexHistory() {
      this.isYearlyIndexShow = true
      this.$root.$emit('bv::show::modal', 'yearly_index_modal')
    },
    hideYearlyIndexHistory() {
      this.isYearlyIndexShow = false
    },

    toggleDailyIndexHybrid() {
      // this.dailyIndex.today.isLoaded = !this.dailyIndex.today.isLoaded
      // this.isDailyIndexHybridOn = !this.isDailyIndexHybridOn
      // this.dailyIndexPreviousYear.isHybrid = !this.dailyIndexPreviousYear.isHybrid
      //
      // setTimeout(() => {
      //   this.dailyIndex.today.isLoaded = !this.dailyIndex.today.isLoaded
      // }, 500)
    },
    toggleWeeklyIndexHybrid() {
      this.isWeeklyIndexHybridOn = !this.isWeeklyIndexHybridOn
    },
    toggleMonthlyIndexHybrid() {
      this.isMonthlyIndexHybridOn = !this.isMonthlyIndexHybridOn
      if (this.isMonthlyIndexHybridOn) {
        this.calculateMonthlyIndex('hybrid')
      } else {
        this.calculateMonthlyIndex('normal')
      }
    },
    toggleYearlyIndexHybrid() {
      this.isYearlyIndexHybridOn = !this.isYearlyIndexHybridOn
    },

    predictTraffic() {
      this.pT.lastMontyTransactions = []
      this.pT.revenueLastMonth = 0
      this.pT.revenueTillToday = 0
      this.pT.revenueFromToday = 0
      this.pT.total = 0
      this.pT.isLoaded = false

      let firstDayOfCurrentMonth = null
      let yesterday = null

      if (this.monthlyIndex.isHybrid) {
        firstDayOfCurrentMonth =
          this.monthlyIndex.normal.currentMonth.date.firstDay.start.formatted
        yesterday = format(
          addDays(
            this.monthlyIndex.normal.currentMonth.date.currentDay.end.original,
            -1
          ),
          "yyyy-MM-dd'T'HH:mm"
        )
      } else {
        firstDayOfCurrentMonth =
          this.monthlyIndex.normal.currentMonth.date.firstDay.start.formatted
        yesterday = format(
          addDays(
            this.monthlyIndex.normal.currentMonth.date.currentDay.end.original,
            -1
          ),
          "yyyy-MM-dd'T'HH:mm"
        )
      }

      let firstDayOfPastMonthMonth = null
      let lastDayOfPastMonthMonth = null

      // if (this.monthlyIndex.isHybrid) {
      //   firstDayOfPastMonthMonth = nextMonday(
      //     startOfMonth(addMonths(new Date(), -1))
      //   )
      //   lastDayOfPastMonthMonth = addDays(firstDayOfPastMonthMonth, +30)
      // } else {
      //   firstDayOfPastMonthMonth = this.monthlyIndex.normal.pastMonth.date
      //     .firstDay.start.formatted
      //   lastDayOfPastMonthMonth = this.monthlyIndex.normal.pastMonth.date
      //     .lastDay.end.formatted
      // }

      firstDayOfPastMonthMonth =
        this.monthlyIndex.normal.pastMonth.date.firstDay.start.formatted
      lastDayOfPastMonthMonth =
        this.monthlyIndex.normal.pastMonth.date.lastDay.end.formatted

      let monday = 0
      let tuesday = 0
      let wednesday = 0
      let thursday = 0
      let friday = 0
      let saturday = 0
      let sunday = 0

      let mondayRevenue = 0
      let tuesdayRevenue = 0
      let wednesdayRevenue = 0
      let thursdayRevenue = 0
      let fridayRevenue = 0
      let saturdayRevenue = 0
      let sundayRevenue = 0

      let averageMondayRevenue = 0
      let averageTuesdayRevenue = 0
      let averageWednesdayRevenue = 0
      let averageThursdayRevenue = 0
      let averageFridayRevenue = 0
      let averageSaturdayRevenue = 0
      let averageSundayRevenue = 0

      this.monthlyIndex.normal.pastMonth.days.forEach((day) => {
        if (isMonday(day)) monday++
        if (isTuesday(day)) tuesday++
        if (isWednesday(day)) wednesday++
        if (isThursday(day)) thursday++
        if (isFriday(day)) friday++
        if (isSaturday(day)) saturday++
        if (isSunday(day)) sunday++
      })

      ApiService.get(
        'company_transactions',
        `?oib=${this.currentUserCompanyOib}&date[after]=${firstDayOfCurrentMonth}&date[before]=${yesterday}&order[createdAt]=desc&pagination=false`
      ).then(({ data }) => {
        data['hydra:member'].forEach((day) => {
          this.pT.revenueTillToday += parseFloat(day.total)
        })

        ApiService.get(
          'company_transactions',
          `?oib=${this.currentUserCompanyOib}&date[after]=${firstDayOfPastMonthMonth}&date[before]=${lastDayOfPastMonthMonth}&pagination=false`
        ).then(({ data }) => {
          this.pT.lastMontyTransactions = [...data['hydra:member']]

          this.pT.lastMontyTransactions.forEach((lMDay) => {
            this.pT.revenueLastMonth += lMDay.total

            if (isMonday(new Date(lMDay.date))) mondayRevenue += lMDay.total
            if (isTuesday(new Date(lMDay.date))) tuesdayRevenue += lMDay.total
            if (isWednesday(new Date(lMDay.date)))
              wednesdayRevenue += lMDay.total
            if (isThursday(new Date(lMDay.date))) thursdayRevenue += lMDay.total
            if (isFriday(new Date(lMDay.date))) fridayRevenue += lMDay.total
            if (isSaturday(new Date(lMDay.date))) saturdayRevenue += lMDay.total
            if (isSunday(new Date(lMDay.date))) sundayRevenue += lMDay.total
          })

          averageMondayRevenue = mondayRevenue / monday
          averageTuesdayRevenue = tuesdayRevenue / tuesday
          averageWednesdayRevenue = wednesdayRevenue / wednesday
          averageThursdayRevenue = thursdayRevenue / thursday
          averageFridayRevenue = fridayRevenue / friday
          averageSaturdayRevenue = saturdayRevenue / saturday
          averageSundayRevenue = sundayRevenue / sunday

          const monthlyIndex = (
            (this.monthlyIndex.normal.currentMonth.total /
              this.monthlyIndex.normal.pastMonth.total -
              1) *
            100
          ).toFixed(2)

          this.monthlyIndex.normal.currentMonth.days.forEach((day) => {
            if (this.today <= addDays(day, +1)) {
              if (isMonday(day))
                this.pT.revenueFromToday +=
                  averageMondayRevenue +
                  (averageMondayRevenue * monthlyIndex) / 100
              if (isTuesday(day))
                this.pT.revenueFromToday +=
                  averageTuesdayRevenue +
                  (averageTuesdayRevenue * monthlyIndex) / 100
              if (isWednesday(day))
                this.pT.revenueFromToday +=
                  averageWednesdayRevenue +
                  (averageWednesdayRevenue * monthlyIndex) / 100
              if (isThursday(day))
                this.pT.revenueFromToday +=
                  averageThursdayRevenue +
                  (averageThursdayRevenue * monthlyIndex) / 100
              if (isFriday(day))
                this.pT.revenueFromToday +=
                  averageFridayRevenue +
                  (averageFridayRevenue * monthlyIndex) / 100
              if (isSaturday(day))
                this.pT.revenueFromToday +=
                  averageSaturdayRevenue +
                  (averageSaturdayRevenue * monthlyIndex) / 100
              if (isSunday(day))
                this.pT.revenueFromToday +=
                  averageSundayRevenue +
                  (averageSundayRevenue * monthlyIndex) / 100
            }
          })

          this.pT.total = this.pT.revenueTillToday + this.pT.revenueFromToday

          setTimeout(() => {
            this.pT.isLoaded = true
          }, 1000)
        })
      })
    },

    // problematic & critical devices
    toggleProblematicDevicesModal() {
      this.$root.$emit('bv::show::modal', 'problematic_devices')
    },
    toggleCriticalDevicesModal() {
      this.$root.$emit('bv::show::modal', 'critical_devices')
    },

    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
    yesterday() {
      this.$refs.picker.updateValue({
        start: addDays(new Date(this.today), -2).setHours(24, 0, 0),
        end: addDays(new Date(this.today), -1).setHours(23, 59, 59),
      })
    },
    last7Days() {
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -8 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -31 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({
        start: startOfMonth(this.today),
        end: this.today,
      })
    },
    prevMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },

    fetchLastTransaction(device) {
      this.problematicDeviceMsg.msg_ts = device.eventMeta['lastPay']
      this.problematicDeviceMsg.all_msg = JSON.stringify(
        device.eventMeta,
        null,
        2
      )
      this.$root.$emit('bv::show::modal', 'problematic_device_msg')
      // ApiService.get(
      //     'transactions',
      //     `?imei=${imei}&order[createdAt]=desc&pagination=false`
      // ).then(({data}) => {
      //   this.problematicDeviceMsg = data['hydra:member'][0]['createdAt']
      //   this.$root.$emit('bv::show::modal', 'problematic_device_msg')
      // })
    },

    fetchLastMessage(device) {
      this.criticalDeviceMsg.msg_ts = device.eventMeta['lastMessage']
      this.criticalDeviceMsg.all_msg = JSON.stringify(device.eventMeta, null, 2)
      this.$root.$emit('bv::show::modal', 'critical_device_msg')
      // ApiService.get(
      //     'messages',
      //     `?imei=${imei}&order[createdAt]=desc&pagination=false`
      // ).then(({data}) => {
      //   this.criticalDeviceMsg = data['hydra:member'][0]['createdAt']
      //   this.$root.$emit('bv::show::modal', 'critical_device_msg')
      // })
    },

    // problematic devices sort
    problematicDeviceOrderByLocation() {
      this.problematicDeviceLocationOrder.isActive = true

      if (this.problematicDeviceLocationOrder.query === 'asc')
        this.problematicDeviceLocationOrder.query = 'desc'
      else this.problematicDeviceLocationOrder.query = 'asc'

      this.isProblematicDevicesLoaded = false
      this.problematicDevices = []

      if (this.problematicDeviceLocationOrder.query === 'asc') {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isProblematic=true&order[location.title]=desc`
        ).then(({ data }) => {
          this.problematicDevices = [...data['hydra:member']]
          this.isProblematicDevicesLoaded = true
          this.problematicDeviceLocationOrder.query = 'asc'
        })
      } else {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isProblematic=true&order[location.title]=asc`
        ).then(({ data }) => {
          this.problematicDevices = [...data['hydra:member']]
          this.isProblematicDevicesLoaded = true
          this.problematicDeviceLocationOrder.query = 'desc'
        })
      }
    },
    problematicDeviceOrderByLessor() {
      this.problematicDeviceLessorOrder.isActive = true

      if (this.problematicDeviceLessorOrder.query === 'asc')
        this.problematicDeviceLessorOrder.query = 'desc'
      else this.problematicDeviceLessorOrder.query = 'asc'

      this.isProblematicDevicesLoaded = false
      this.problematicDevices = []

      if (this.problematicDeviceLessorOrder.query === 'asc') {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isProblematic=true&order[location.lessor.name]=desc`
        ).then(({ data }) => {
          this.problematicDevices = [...data['hydra:member']]
          this.isProblematicDevicesLoaded = true
          this.problematicDeviceLessorOrder.query = 'asc'
        })
      } else {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isProblematic=true&order[location.lessor.name]=asc`
        ).then(({ data }) => {
          this.problematicDevices = [...data['hydra:member']]
          this.isProblematicDevicesLoaded = true
          this.problematicDeviceLessorOrder.query = 'desc'
        })
      }
    },
    problematicDeviceOrderByServiceMode() {
      this.problematicDeviceServiceModeOrder.isActive = true

      if (this.problematicDeviceServiceModeOrder.query === 'asc')
        this.problematicDeviceServiceModeOrder.query = 'desc'
      else this.problematicDeviceServiceModeOrder.query = 'asc'

      this.isProblematicDevicesLoaded = false
      this.problematicDevices = []

      if (this.problematicDeviceServiceModeOrder.query === 'asc') {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isProblematic=true&order[serviceMode]=desc`
        ).then(({ data }) => {
          this.problematicDevices = [...data['hydra:member']]
          this.isProblematicDevicesLoaded = true
          this.problematicDeviceServiceModeOrder.query = 'asc'
        })
      } else {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isProblematic=true&order[serviceMode]=asc`
        ).then(({ data }) => {
          this.problematicDevices = [...data['hydra:member']]
          this.isProblematicDevicesLoaded = true
          this.problematicDeviceServiceModeOrder.query = 'desc'
        })
      }
    },
    problematicDeviceOrderByTime() {
      this.problematicDeviceTimeOrder.isActive = true

      if (this.problematicDeviceTimeOrder.query === 'asc')
        this.problematicDeviceTimeOrder.query = 'desc'
      else this.problematicDeviceTimeOrder.query = 'asc'

      this.isProblematicDevicesLoaded = false

      if (this.problematicDeviceTimeOrder.query === 'asc') {
        this.problematicDevices.sort(function (a, b) {
          return (
            new Date(b.lastTransactionDateTime) -
            new Date(a.lastTransactionDateTime)
          )
        })
        this.isProblematicDevicesLoaded = true
        this.problematicDeviceTimeOrder.query = 'asc'
      } else {
        this.problematicDevices.sort(function (a, b) {
          return (
            new Date(a.lastTransactionDateTime) -
            new Date(b.lastTransactionDateTime)
          )
        })
        this.isProblematicDevicesLoaded = true
        this.problematicDeviceTimeOrder.query = 'desc'
      }
    },

    // critical devices sort
    criticalDeviceOrderByLocation() {
      this.criticalDeviceLocationOrder.isActive = true

      if (this.criticalDeviceLocationOrder.query === 'asc')
        this.criticalDeviceLocationOrder.query = 'desc'
      else this.criticalDeviceLocationOrder.query = 'asc'

      this.isCriticalDevicesLoaded = false
      this.criticalDevices = []

      if (this.criticalDeviceLocationOrder.query === 'asc') {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isCritical=true&order[location.title]=desc`
        ).then(({ data }) => {
          this.criticalDevices = [...data['hydra:member']]
          this.isCriticalDevicesLoaded = true
          this.criticalDeviceLocationOrder.query = 'asc'
        })
      } else {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isCritical=true&order[location.title]=asc`
        ).then(({ data }) => {
          this.criticalDevices = [...data['hydra:member']]
          this.isCriticalDevicesLoaded = true
          this.criticalDeviceLocationOrder.query = 'desc'
        })
      }
    },
    criticalDeviceOrderByLessor() {
      this.criticalDeviceLessorOrder.isActive = true

      if (this.criticalDeviceLessorOrder.query === 'asc')
        this.criticalDeviceLessorOrder.query = 'desc'
      else this.criticalDeviceLessorOrder.query = 'asc'

      this.isCriticalDevicesLoaded = false
      this.criticalDevices = []

      if (this.criticalDeviceLessorOrder.query === 'asc') {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isCritical=true&order[location.lessor.name]=desc`
        ).then(({ data }) => {
          this.criticalDevices = [...data['hydra:member']]
          this.isCriticalDevicesLoaded = true
          this.criticalDeviceLessorOrder.query = 'asc'
        })
      } else {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isCritical=true&order[location.lessor.name]=asc`
        ).then(({ data }) => {
          this.criticalDevices = [...data['hydra:member']]
          this.isCriticalDevicesLoaded = true
          this.criticalDeviceLessorOrder.query = 'desc'
        })
      }
    },
    criticalDeviceOrderByServiceMode() {
      this.criticalDeviceServiceModeOrder.isActive = true

      if (this.criticalDeviceServiceModeOrder.query === 'asc')
        this.criticalDeviceServiceModeOrder.query = 'desc'
      else this.criticalDeviceServiceModeOrder.query = 'asc'

      this.isCriticalDevicesLoaded = false
      this.criticalDevices = []

      if (this.criticalDeviceServiceModeOrder.query === 'asc') {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isCritical=true&order[serviceMode]=desc`
        ).then(({ data }) => {
          this.criticalDevices = [...data['hydra:member']]
          this.isCriticalDevicesLoaded = true
          this.criticalDeviceServiceModeOrder.query = 'asc'
        })
      } else {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&isCritical=true&order[serviceMode]=asc`
        ).then(({ data }) => {
          this.criticalDevices = [...data['hydra:member']]
          this.isCriticalDevicesLoaded = true
          this.criticalDeviceServiceModeOrder.query = 'desc'
        })
      }
    },
    criticalDeviceOrderByTime() {
      this.criticalDeviceTimeOrder.isActive = true

      if (this.criticalDeviceTimeOrder.query === 'asc')
        this.criticalDeviceTimeOrder.query = 'desc'
      else this.criticalDeviceTimeOrder.query = 'asc'

      this.isCriticalDevicesLoaded = false

      if (this.criticalDeviceTimeOrder.query === 'asc') {
        this.criticalDevices.sort(function (a, b) {
          return (
            new Date(b.lastMessageDateTime) - new Date(a.lastMessageDateTime)
          )
        })
        this.isCriticalDevicesLoaded = true
        this.criticalDeviceTimeOrder.query = 'asc'
      } else {
        this.criticalDevices.sort(function (a, b) {
          return (
            new Date(a.lastMessageDateTime) - new Date(b.lastMessageDateTime)
          )
        })
        this.isCriticalDevicesLoaded = true
        this.criticalDeviceTimeOrder.query = 'desc'
      }
    },

    fetchAllLastTransactions() {
      // let primaryKey = 0
      this.problematicDevices.forEach((device, key) => {
        // console.log(device, key)
        this.problematicDevices[key].lastTransactionDateTime =
          device.eventMeta['lastPay']

        // ApiService.get('messages', device.lastPayMessage).then(({data}) => {
        //   primaryKey++
        //   this.problematicDevices[key].lastTransactionDateTime = data.createdAt
        //
        //   if (this.problematicDevices.length === primaryKey) {
        //     this.isProblematicDevicesTimeLoaded = true
        //   }
        // }).catch(error => {
        //   console.log(error)
        // })
      })
      this.isProblematicDevicesTimeLoaded = true
      this.$forceUpdate()
    },

    fetchAllLastMessages() {
      // let primaryKey = 0
      this.criticalDevices.forEach((device, key) => {
        this.criticalDevices[key].lastMessageDateTime =
          device.eventMeta['lastMessage']

        // if (device.lastMessage) {
        //   ApiService.get('messages', device.lastMessage).then(({data}) => {
        //     primaryKey++
        //     this.criticalDevices[key].lastMessageDateTime = data.createdAt
        //
        //     if (this.criticalDevices.length === primaryKey) {
        //       this.isCriticalDevicesTimeLoaded = true
        //     }
        //   }).catch(error => {
        //     console.log(error)
        //   })
        //
        // } else {
        //   primaryKey++
        // }
      })
      this.isCriticalDevicesTimeLoaded = true
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss">
.fs-1-5x {
  font-size: 1.5rem !important;
}

.fs-2x {
  font-size: 2rem !important;
}

.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}

.symbol-hover:hover {
  -webkit-box-shadow: 0px 9px 16px 0px rgb(55 153 255 / 30%) !important;
  box-shadow: 0px 9px 16px 0px rgb(55 153 255 / 30%);
}
</style>
